var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "40%",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c(
        "div",
        { staticClass: "contents" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.dialogLoading,
                  expression: "dialogLoading",
                },
              ],
              ref: "refForm",
              attrs: {
                "label-width": "150px",
                model: _vm.formData,
                rules: _vm.formRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "大客户姓名", prop: "fullname" } },
                [
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入大客户姓名",
                          clearable: "",
                        },
                        model: {
                          value: _vm.formData.fullname,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "fullname", $$v)
                          },
                          expression: "formData.fullname",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话", prop: "mobile" } },
                [
                  _c(
                    "div",
                    [
                      _c("el-input", {
                        attrs: {
                          maxLength: "11",
                          placeholder: "请输入联系电话",
                          clearable: "",
                        },
                        model: {
                          value: _vm.formData.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "mobile", $$v)
                          },
                          expression: "formData.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "归属城市仓", prop: "logistics_id" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "选择城市仓",
                          },
                          on: { change: _vm.onChangeCityStore },
                          model: {
                            value: _vm.formData.logistics_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "logistics_id", $$v)
                            },
                            expression: "formData.logistics_id",
                          },
                        },
                        _vm._l(_vm.cityStoreList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自提点", prop: "delivery_id" } },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            clearable: "",
                            placeholder: "选择自提点",
                          },
                          model: {
                            value: _vm.formData.delivery_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "delivery_id", $$v)
                            },
                            expression: "formData.delivery_id",
                          },
                        },
                        _vm._l(_vm.deliveryList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.submitLoading },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }