var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      ref: "multipleTable",
      staticStyle: { width: "100%" },
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
        "tooltip-effect": "dark",
      },
    },
    [
      _c("el-table-column", {
        attrs: {
          prop: "fullname",
          align: "center",
          label: "客户名称",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.fullname || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "mobile",
          align: "center",
          label: "联系电话",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.mobile || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "name",
          align: "center",
          label: "归属城市仓",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (scope.row.logistics && scope.row.logistics.name) || "-"
                    )
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "delivery",
          align: "center",
          label: "自提点",
          "min-width": "120",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(
                      (scope.row.delivery && scope.row.delivery.name) || "-"
                    )
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "create_time",
          align: "center",
          label: "注册时间",
          "min-width": "100",
          "show-overflow-tooltip": "",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.create_time || "-") + " ")]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "days",
          align: "center",
          label: "注册天数",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "average_order_number",
          align: "center",
          label: "日均下单数",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "order_number",
          align: "center",
          label: "下单总数",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "average_order_gross_weight",
          align: "center",
          label: "日均下单重量",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "order_gross_weight",
          align: "center",
          label: "下单总重",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "rep_rate",
          align: "center",
          label: "复购率(%)",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "is_vip",
          align: "center",
          label: "是否付费会员",
          "min-width": "100",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(" " + _vm._s(scope.row.is_vip ? "是" : "否") + " "),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "is_corporate",
          align: "center",
          label: "是否对公客户",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "corporate_amt",
          align: "center",
          label: "累计充值金额",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "corporate_consume_amt",
          align: "center",
          label: "累计消费金额",
          "min-width": "100",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "corporate_count",
          align: "center",
          label: "充值次数",
          "min-width": "100",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }