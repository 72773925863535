<template>
  <section class="page-wrap">
    <!-- 顶部操作栏 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>

        <el-button type="primary" @click="onHandleCreate">新 增</el-button>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.fullname"
          placeholder="输入客户名称"
          clearable
        ></el-input>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.mobile"
          placeholder="输入联系电话"
          clearable
        ></el-input>
      </div>

      <div class="form-item">
        <el-select
          filterable
          v-model="formData.logistics_id"
          clearable
          placeholder="选择城市仓"
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-date-picker
          v-model="registerDate"
          @change="onChangeDate"
          type="daterange"
          range-separator="至"
          value-format="yyyy-MM-dd"
          start-placeholder="注册时间起"
          end-placeholder="注册时间止"
        >
        </el-date-picker>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.min_register_day"
          @blur="
            () =>
              (formData.min_register_day = utilsTool.filterUnNumber(
                formData.min_register_day
              ))
          "
          @change="onChangeAmt('min_register_day', 'max_register_day', 0)"
          placeholder="注册天数起"
          clearable
        ></el-input>
        <span class="link">-</span>
        <el-input
          v-model="formData.max_register_day"
          @blur="
            () =>
              (formData.max_register_day = utilsTool.filterUnNumber(
                formData.max_register_day
              ))
          "
          @change="onChangeAmt('min_register_day', 'max_register_day', 0)"
          placeholder="注册天数止"
          clearable
        ></el-input>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.min_weight"
          @blur="
            () =>
              (formData.min_weight = utilsTool.filterUnNumber(
                formData.min_weight,
                true
              ))
          "
          @change="onChangeAmt('min_weight', 'max_weight', 1)"
          placeholder="下单总重起"
          clearable
        ></el-input>
        <span class="link">-</span>
        <el-input
          v-model="formData.max_weight"
          @blur="
            () =>
              (formData.max_weight = utilsTool.filterUnNumber(
                formData.max_weight,
                true
              ))
          "
          @change="onChangeAmt('min_weight', 'max_weight', 1)"
          placeholder="下单总重止"
          clearable
        ></el-input>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.min_order_number"
          @blur="
            () =>
              (formData.min_order_number = utilsTool.filterUnNumber(
                formData.min_order_number
              ))
          "
          @change="onChangeAmt('min_order_number', 'max_order_number', 2)"
          placeholder="下单数量起"
          clearable
        ></el-input>
        <span class="link">-</span>
        <el-input
          v-model="formData.max_order_number"
          @blur="
            () =>
              (formData.max_order_number = utilsTool.filterUnNumber(
                formData.max_order_number
              ))
          "
          @change="onChangeAmt('min_order_number', 'max_order_number', 2)"
          placeholder="下单数量止"
          clearable
        ></el-input>
      </div>

      <div class="form-item">
        <el-select v-model="formData.is_vip" clearable placeholder="是否是会员">
          <el-option
            v-for="item in vipStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-select
          v-model="formData.is_corporate"
          clearable
          placeholder="是否对公用户"
        >
          <el-option
            v-for="item in isCorporateList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
      </div>
    </section>
    <!-- 顶部操作栏 end -->

    <!-- 列表数据 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        @on-edit-row="onHandleEditRow"
      ></table-list>
    </section>
    <!-- 列表数据 end -->

    <!-- 分页 start -->
    <section class="pagination">
      <el-pagination
        type="primary"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </section>
    <!-- 分页 end -->

    <!-- 新增/编辑大客户 start -->
    <edit-big-boss-dialog
      ref="refEditBigBossDialog"
      :cityStoreList="cityStoreList"
      @on-edit-data="onHandleEditData"
    ></edit-big-boss-dialog>
    <!-- 新增/编辑大客户 end -->
  </section>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import TableList from "./modules/table-list/index.vue";
import EditBigBossDialog from "./modules/edit-big-boss-dialog/index";
import { MEMBER_VIP_STATUS, IS_CORPORATE_ENUM } from "./utils/enum/index";
import utilsTool from "@/utils/tools/tool";
export default {
  name: "BigBoss",
  components: {
    TableList,
    EditBigBossDialog,
  },
  data() {
    return {
      loadingData: true,
      utilsTool,
      cityStoreList: [], // 城市仓列表
      vipStatusList: Object.values(MEMBER_VIP_STATUS), // 会员状态列
      isCorporateList: Object.values(IS_CORPORATE_ENUM), // 是否对公转账下拉
      registerDate: [], // 日期对象
      formData: {
        fullname: "", // 客户名称
        mobile: "", // 联系电话
        logistics_id: "", // 城市仓id
        min_register_date: "", // 注册时间起
        max_register_date: "", // 注册时间止
        min_register_day: "", // 注册天数起
        max_register_day: "", // 注册天数止
        min_weight: "", // 下单总重起
        max_weight: "", // 下单总重止
        min_order_number: "", // 下单数量起
        max_order_number: "", // 下单数量止
        is_vip: "", // 是否是会员
        is_corporate: "", // 是否对公用户
        page: 1,
        pageSize: 10,
      },
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
      this.ajaxGetCityList();
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.registerDate = [];
      this.ajaxGetTableData();
    },

    /**
     * 新增数据
     */
    onHandleCreate() {
      this.$refs.refEditBigBossDialog.onInitData();
    },
    /**
     * 编辑数据
     */
    onHandleEditRow(row) {
      const { id } = row;
      const params = {
        id,
        formData: row,
      };
      this.$refs.refEditBigBossDialog.onInitData(params);
    },
    /**
     * 新增/编辑数据后，更新列表
     */
    onHandleEditData() {
      this.ajaxGetTableData();
    },
    /**
     * 日期发生改变
     */
    onChangeDate(data) {
      this.formData.min_register_date = data?.[0] || "";
      this.formData.max_register_date = data?.[1] || "";
    },
    /**
     * 金额发生改变
     * @param {String} first 第一个参数
     * @param {String} last 第二个参数
     * @param {Number} index 对于文案下标
     */
    onChangeAmt(first, last, index) {
      const nameMap = ["注册天数", "下单总重", "下单数量"];
      if (
        this.formData[first] &&
        this.formData[last] &&
        Number(this.formData[first]) > Number(this.formData[last])
      ) {
        this.$message.warning(
          `起始${nameMap[index]}，不得大于截止${nameMap[index]}`
        );
        this.formData[last] = "";
      }
    },
    /**
     * 获取城市仓下拉列
     */
    async ajaxGetCityList() {
      try {
        const { data } = await this.$api.general.logisticsSuper();
        this.cityStoreList = data;
      } catch (err) {
        console.log("ajax general.logisticsSuper err", err);
      }
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      const params = { ...this.formData };
      try {
        const { data } = await this.$api.general.superList(params);
        const { total, data: list } = data;
        this.total = total;
        this.tableData = list;
      } catch (err) {
        console.log("ajax general.superList err", err);
      } finally {
        this.loadingData = false;
      }
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content-wrap {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
}
</style>
