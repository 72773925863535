<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="40%"
    class="dialog"
    center
  >
    <div class="contents">
      <el-form
        ref="refForm"
        label-width="150px"
        v-loading="dialogLoading"
        :model="formData"
        :rules="formRules"
      >
        <el-form-item label="大客户姓名" prop="fullname">
          <div>
            <el-input
              v-model="formData.fullname"
              placeholder="请输入大客户姓名"
              clearable
            ></el-input>
          </div>
        </el-form-item>

        <el-form-item label="联系电话" prop="mobile">
          <div>
            <el-input
              v-model="formData.mobile"
              maxLength="11"
              placeholder="请输入联系电话"
              clearable
            ></el-input>
          </div>
        </el-form-item>

        <el-form-item label="归属城市仓" prop="logistics_id">
          <div>
            <el-select
              @change="onChangeCityStore"
              filterable
              v-model="formData.logistics_id"
              clearable
              placeholder="选择城市仓"
            >
              <el-option
                v-for="item in cityStoreList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>

        <el-form-item label="自提点" prop="delivery_id">
          <div>
            <el-select
              filterable
              v-model="formData.delivery_id"
              clearable
              placeholder="选择自提点"
            >
              <el-option
                v-for="item in deliveryList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="onHandleClose">取 消</el-button>
      <el-button type="primary" :loading="submitLoading" @click="onHandleSubmit"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "EditBigBossDialog",
  props: {
    /**
     * 城市仓列表
     */
    cityStoreList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogLoading: false,
      submitLoading: false,
      title: "新增大客户",
      deliveryList: [], // 自提点列表
      formData: {
        fullname: "", // 大客户姓名
        mobile: "", // 联系电话
        logistics_id: "", // 城市仓id
        delivery_id: "", // 自提点id
      },
      formDataClone: {}, // 拷贝一份数据，用于初始化
      formRules: {
        fullname: [
          {
            required: true,
            message: "请输入大客户姓名",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
            message: "该手机号码不符合规范",
            trigger: ["blur"],
          },
        ],
        logistics_id: [
          { required: true, message: "请选择归属城市仓", trigger: "change" },
        ],
        delivery_id: [
          { required: true, message: "请选择自提点", trigger: "change" },
        ],
      }, // 表单校验
      dataId: null, // 编辑数据的id
    };
  },
  methods: {
    /**
     * 初始化表单
     */
    onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      if (data?.id) {
        this.dataId = data.id;
        this.title = "编辑大客户";
        this.onInitEditForm(data);
      }
      this.$refs.refForm?.clearValidate();
    },
    /**
     * 关闭弹窗
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.dialogVisible = false;
      this.dataId = null;
      this.title = "新增大客户";
      this.$refs.refForm.clearValidate();
    },
    /**
     * 初始化编辑页面
     */
    onInitEditForm(data) {
      this.dialogLoading = true;
      Object.keys(this.formData).forEach((_KEY) => {
        this.formData[_KEY] = data.formData[_KEY];
      });
      // this.formData = {
      //   ...data.formData,
      // };
      this.ajaxGetDeliveryList(this.formData.logistics_id);
      this.dialogLoading = false;
    },
    /**
     * 变更了城市仓
     */
    onChangeCityStore(data) {
      this.formData.delivery_id = "";
      this.ajaxGetDeliveryList(data);
    },
    /**
     * 获取自提点列表
     */
    async ajaxGetDeliveryList(data) {
      const params = {
        logistics_id: data,
      };
      try {
        const { data } = await this.$api.general.deliverySuper(params);
        console.log("🆒 ajaxGetDeliveryList", data);
        this.deliveryList = data;
      } catch (err) {
        console.log("ajx general.deliverySuper err", err);
      }
    },
    /**
     * 提交数据
     */
    onHandleSubmit() {
      this.$refs.refForm.validate((valid) => {
        if (!valid) {
          return;
        }
        this.submitLoading = true;
        const params = {
          ...this.formData,
        };
        this.dataId && Object.assign(params, { id: this.dataId });
        this.ajaxPostSaveData(params);
      });
    },
    /**
     *  保存数据
     */
    async ajaxPostSaveData(params) {
      try {
        await this.$api.general.addSuper(params);
        this.$message.success("保存成功");
        this.onHandleClose();
        this.$emit("on-edit-data");
      } catch (err) {
        console.log("ajax general.addSuper err", err);
      } finally {
        this.submitLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .contents {
    padding: 30px 20px 20px;
    box-sizing: border-box;
    .el-form {
      &-item {
        .el-select,
        .el-input {
          width: 90%;
        }
      }
    }
  }
}
</style>
