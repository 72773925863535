var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page-wrap" },
    [
      _c("section", { staticClass: "form jn-form-style" }, [
        _c(
          "div",
          { staticClass: "form-item btn-wrap" },
          [
            _c("el-button", {
              staticClass: "refresh",
              staticStyle: { "margin-left": "5px" },
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.onHandleRefresh },
            }),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.onHandleCreate } },
              [_vm._v("新 增")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "输入客户名称", clearable: "" },
              model: {
                value: _vm.formData.fullname,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "fullname", $$v)
                },
                expression: "formData.fullname",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "输入联系电话", clearable: "" },
              model: {
                value: _vm.formData.mobile,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "mobile", $$v)
                },
                expression: "formData.mobile",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c(
              "el-select",
              {
                attrs: {
                  filterable: "",
                  clearable: "",
                  placeholder: "选择城市仓",
                },
                model: {
                  value: _vm.formData.logistics_id,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "logistics_id", $$v)
                  },
                  expression: "formData.logistics_id",
                },
              },
              _vm._l(_vm.cityStoreList, function (item) {
                return _c("el-option", {
                  key: item.id,
                  attrs: { label: item.name, value: item.id },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-date-picker", {
              attrs: {
                type: "daterange",
                "range-separator": "至",
                "value-format": "yyyy-MM-dd",
                "start-placeholder": "注册时间起",
                "end-placeholder": "注册时间止",
              },
              on: { change: _vm.onChangeDate },
              model: {
                value: _vm.registerDate,
                callback: function ($$v) {
                  _vm.registerDate = $$v
                },
                expression: "registerDate",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "注册天数起", clearable: "" },
              on: {
                blur: () =>
                  (_vm.formData.min_register_day = _vm.utilsTool.filterUnNumber(
                    _vm.formData.min_register_day
                  )),
                change: function ($event) {
                  return _vm.onChangeAmt(
                    "min_register_day",
                    "max_register_day",
                    0
                  )
                },
              },
              model: {
                value: _vm.formData.min_register_day,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "min_register_day", $$v)
                },
                expression: "formData.min_register_day",
              },
            }),
            _c("span", { staticClass: "link" }, [_vm._v("-")]),
            _c("el-input", {
              attrs: { placeholder: "注册天数止", clearable: "" },
              on: {
                blur: () =>
                  (_vm.formData.max_register_day = _vm.utilsTool.filterUnNumber(
                    _vm.formData.max_register_day
                  )),
                change: function ($event) {
                  return _vm.onChangeAmt(
                    "min_register_day",
                    "max_register_day",
                    0
                  )
                },
              },
              model: {
                value: _vm.formData.max_register_day,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "max_register_day", $$v)
                },
                expression: "formData.max_register_day",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "下单总重起", clearable: "" },
              on: {
                blur: () =>
                  (_vm.formData.min_weight = _vm.utilsTool.filterUnNumber(
                    _vm.formData.min_weight,
                    true
                  )),
                change: function ($event) {
                  return _vm.onChangeAmt("min_weight", "max_weight", 1)
                },
              },
              model: {
                value: _vm.formData.min_weight,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "min_weight", $$v)
                },
                expression: "formData.min_weight",
              },
            }),
            _c("span", { staticClass: "link" }, [_vm._v("-")]),
            _c("el-input", {
              attrs: { placeholder: "下单总重止", clearable: "" },
              on: {
                blur: () =>
                  (_vm.formData.max_weight = _vm.utilsTool.filterUnNumber(
                    _vm.formData.max_weight,
                    true
                  )),
                change: function ($event) {
                  return _vm.onChangeAmt("min_weight", "max_weight", 1)
                },
              },
              model: {
                value: _vm.formData.max_weight,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "max_weight", $$v)
                },
                expression: "formData.max_weight",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "下单数量起", clearable: "" },
              on: {
                blur: () =>
                  (_vm.formData.min_order_number = _vm.utilsTool.filterUnNumber(
                    _vm.formData.min_order_number
                  )),
                change: function ($event) {
                  return _vm.onChangeAmt(
                    "min_order_number",
                    "max_order_number",
                    2
                  )
                },
              },
              model: {
                value: _vm.formData.min_order_number,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "min_order_number", $$v)
                },
                expression: "formData.min_order_number",
              },
            }),
            _c("span", { staticClass: "link" }, [_vm._v("-")]),
            _c("el-input", {
              attrs: { placeholder: "下单数量止", clearable: "" },
              on: {
                blur: () =>
                  (_vm.formData.max_order_number = _vm.utilsTool.filterUnNumber(
                    _vm.formData.max_order_number
                  )),
                change: function ($event) {
                  return _vm.onChangeAmt(
                    "min_order_number",
                    "max_order_number",
                    2
                  )
                },
              },
              model: {
                value: _vm.formData.max_order_number,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "max_order_number", $$v)
                },
                expression: "formData.max_order_number",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "是否是会员" },
                model: {
                  value: _vm.formData.is_vip,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "is_vip", $$v)
                  },
                  expression: "formData.is_vip",
                },
              },
              _vm._l(_vm.vipStatusList, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item" },
          [
            _c(
              "el-select",
              {
                attrs: { clearable: "", placeholder: "是否对公用户" },
                model: {
                  value: _vm.formData.is_corporate,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "is_corporate", $$v)
                  },
                  expression: "formData.is_corporate",
                },
              },
              _vm._l(_vm.isCorporateList, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "form-item btn-wrap" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: { click: _vm.onHandleSearch },
              },
              [_vm._v("查询")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "section",
        { staticClass: "content-wrap" },
        [
          _c("table-list", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingData,
                expression: "loadingData",
              },
            ],
            attrs: { tableData: _vm.tableData },
            on: { "on-edit-row": _vm.onHandleEditRow },
          }),
        ],
        1
      ),
      _c(
        "section",
        { staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              total: _vm.total,
              layout: "total, sizes, prev, pager, next, jumper",
              background: "",
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("edit-big-boss-dialog", {
        ref: "refEditBigBossDialog",
        attrs: { cityStoreList: _vm.cityStoreList },
        on: { "on-edit-data": _vm.onHandleEditData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }