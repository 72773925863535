<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    ref="multipleTable"
    tooltip-effect="dark"
    style="width: 100%"
  >
    <el-table-column
      prop="fullname"
      align="center"
      label="客户名称"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ scope.row.fullname || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="mobile"
      align="center"
      label="联系电话"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.mobile || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="name"
      align="center"
      label="归属城市仓"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{
          (scope.row.logistics && scope.row.logistics.name) || "-"
        }}</span>
      </template>
    </el-table-column>

    <el-table-column
      prop="delivery"
      align="center"
      label="自提点"
      min-width="120"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{
          (scope.row.delivery && scope.row.delivery.name) || "-"
        }}</span>
      </template>
    </el-table-column>

    <el-table-column
      prop="create_time"
      align="center"
      label="注册时间"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ scope.row.create_time || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="days"
      align="center"
      label="注册天数"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="average_order_number"
      align="center"
      label="日均下单数"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="order_number"
      align="center"
      label="下单总数"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="average_order_gross_weight"
      align="center"
      label="日均下单重量"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="order_gross_weight"
      align="center"
      label="下单总重"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="rep_rate"
      align="center"
      label="复购率(%)"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="is_vip"
      align="center"
      label="是否付费会员"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.is_vip ? "是" : "否" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="is_corporate"
      align="center"
      label="是否对公客户"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="corporate_amt"
      align="center"
      label="累计充值金额"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="corporate_consume_amt"
      align="center"
      label="累计消费金额"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="corporate_count"
      align="center"
      label="充值次数"
      min-width="100"
    >
    </el-table-column>

    <!-- <el-table-column fixed="right" label="操作" min-width="120" align="center">
      <template slot-scope="scope">
        <el-button type="text" @click="onHandleEdit(scope.row)">编辑</el-button>
      </template>
    </el-table-column> -->
  </el-table>
</template>

<script>
export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * 编辑
     */
    onHandleEdit(row) {
      this.$emit("on-edit-row", row);
    },
  },
};
</script>

<style lang="scss" scoped></style>
